import { userService } from '@/helpers/authservice/user.service'
import router from '../../router/index'

export const state = {
  currentUser: localStorage.getItem('auth.currentUser'),
}

export const getters = {
  // Whether the user is currently logged in.
  loggedIn (state) {
    return !!state.currentUser
  },
  userName (state) {
    return state.currentUser
  }
}

export const actions = {
  // Logs in the user.
  // eslint-disable-next-line no-unused-vars
  login ({ dispatch, commit }, { email, password }) {
    commit('loginRequest', { email })
    return new Promise((resolve, reject) => {
      userService.login(email, password)
        .then(
          token => {
            commit('loginSuccess', true)
            commit('SET_TOKEN', token)
            userService.validate()
              .then(data => {
                commit('SET_CURRENT_USER', data)
                router.push('/')
                resolve(token)
              })
          },
          error => {
            commit('loginFailure', error)
            dispatch('notification/error', error, { root: true })
            reject({ error: error })
          }
        )
    })
  },
  // Logout the user
  logout ({ commit }) {
    userService.logout()
    commit('logout')
  },
  // register the user
  registeruser ({ dispatch, commit }, user) {
    commit('registerRequest', user)
    return new Promise((resolve, reject) => {
      userService.register(user)
        .then(
          user => {
            //console.log(user)
            commit('registerSuccess', user)
            dispatch('notification/success', 'Registration successful', { root: true })
            router.push('/login')
            resolve()
          },
          error => {
            //console.log(error)
            commit('registerFailure', error)
            dispatch('notification/error', error, { root: true })
            reject(error)
          }
        )
    })
  },
  //validate user
  validate ({ commit, state }) {
    if (!state.currentUser) return Promise.resolve(null)
    return userService.validate()
      .then(data => {
        commit('SET_CURRENT_USER', data)
        commit('SET_TOKEN', localStorage.getItem('token'))
        return data
      })
  },
  // Login with google
  loginWithGoogle ({ dispatch, commit }, { idToken }) {
    commit('loginRequest', { idToken })
    return new Promise((resolve, reject) => {
      userService.googleLogin(idToken)
          .then(
              token => {
                commit('loginSuccess', true)
                commit('SET_TOKEN', token)
                userService.validate()
                    .then(data => {
                      commit('SET_CURRENT_USER', data)
                      router.push('/')
                      resolve(token)
                    })
              },
              error => {
                commit('loginFailure', error)
                dispatch('notification/error', error, { root: true })
                reject({ error: error })
              }
          )
    })
  },
}

export const mutations = {
  loginRequest (state, user) {
    state.status = { loggingIn: true }
    state.user = user
  },
  loginSuccess (state, user) {
    state.status = { loggeduser: true }
    state.user = user
  },
  loginFailure (state) {
    state.status = {}
    state.user = null
  },
  logout (state) {
    state.status = {}
    state.user = null
  },
  registerRequest (state) {
    state.status = { registering: true }
  },
  registerSuccess (state) {
    state.status = {}
  },
  registerFailure (state) {
    state.status = {}
  },
  SET_CURRENT_USER (state, newValue) {
    state.currentUser = newValue
    saveState('auth.currentUser', newValue)
  },
  SET_TOKEN (state, newValue) {
    state.token = newValue
  }
}

function saveState (key, state) {
  window.localStorage.setItem(key, JSON.stringify(state))
}

