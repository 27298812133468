<template>
  <notifications group="foo"/>
  <router-view></router-view>
  <vue-confirm-dialog></vue-confirm-dialog>
</template>

<style>
.vue-notification.error {
  color: white;
}
</style>

<script>
import VueConfirmDialog from './components/ConfirmationDialog/index.vue'

export default {
  name: 'App',
  inject: ['mixpanel'],
  components: {
    VueConfirmDialog
  },
  mounted () {
    this.mixpanel.track('Site Open')
  }
}
</script>
