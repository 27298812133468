import { authHeader } from './auth-header'

export const userService = {
  login,
  logout,
  register,
  getAll,
  validate,
  googleLogin
}

function login (email, password) {

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, password })
  }

  return fetch(`${process.env.VUE_APP_BASE_URL_USER_SERVICE}/v1/auth/signin`, requestOptions)
    .then(handleResponse)
    .then(response => {
      if (response.value) {
        localStorage.setItem('token', response.value)
      }
      return response.value
    })
}

function googleLogin (idToken) {

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id_token: idToken })
  }

  return fetch(`${process.env.VUE_APP_BASE_URL_USER_SERVICE}/v1/auth/login/google`, requestOptions)
      .then(handleResponse)
      .then(response => {
        if (response.value) {
          localStorage.setItem('token', response.value)
        }
        return response.value
      })
}

function logout () {
  localStorage.clear()
  window.sessionStorage.clear()
  location.reload(true)
}

function register (user) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user)
  }
  return fetch(`${process.env.VUE_APP_BASE_URL_USER_SERVICE}/v1/auth/register`, requestOptions).then(handleResponse)
}

function getAll () {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }
  return fetch(`/users`, requestOptions).then(handleResponse)
}

function validate () {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('token')}` },
  }
  return fetch(`${process.env.VUE_APP_BASE_URL_USER_SERVICE}/v1/user/me`, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user.value
    })
}

function handleResponse (response) {
  if (response.status === 401) {
    logout()
    return;
  }
  return response.text().then(text => {
    const data = text && JSON.parse(text)
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout()
      }
      const error = (data && data.error && data.error.message) || response.statusText
      return Promise.reject(error)
    }
    return data
  })
}
