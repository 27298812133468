import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import BootstrapVue3 from 'bootstrap-vue-3'
import VueApexCharts from 'vue3-apexcharts'
import vClickOutside from 'click-outside-vue3'
import {registerScrollSpy} from 'vue3-scroll-spy'
import Notifications from '@kyvg/vue3-notification'
import VueMixpanel from 'vue-mixpanel'
import '@vuepic/vue-datepicker/dist/main.css'
import vue3GoogleLogin from 'vue3-google-login'
import * as Sentry from "@sentry/vue";

import Maska from 'maska'
import {i18n} from './i18n.js'

import 'sweetalert2/dist/sweetalert2.min.css'
import '@vueform/slider/themes/default.css'

import store from './state/store'

import '../src/design/app.scss'

import VueTour from 'v3-tour'

require('@/state/subscriber')

require('v3-tour/dist/vue-tour.css')

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL_SUBSCRIPTION_SERVICE

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response.status === 401) {
            store.dispatch('authfack/logout')
            return;
        }
        throw error;
    }
);

const app = createApp(App)
    .use(store)
    .use(require('vue-chartist'))
    .use(BootstrapVue3)
    .use(VueApexCharts)
    .use(vClickOutside)
    .use(i18n)
    .use(registerScrollSpy)
    .use(Maska)
    .use(Notifications)
    .use(VueTour)
    .use(VueMixpanel, {
        token: process.env.VUE_APP_MIXPANEL_ID,
        config: {
            debug: false
        }
    })
    .use(vue3GoogleLogin, {
        clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID
    })


Sentry.init({
    app,
    dsn: "https://a65c733acf1255e0641360bd1b7a54de@o4507275128340480.ingest.us.sentry.io/4507275129585664",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.use(router);
app.mount("#app");
